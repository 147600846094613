import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = { class: "flex justify-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_checkbox = _resolveComponent("el-checkbox")!

  return (_openBlock(), _createBlock($setup["Form"], {
    as: "el-form",
    "validation-schema": $setup.schema,
    onSubmit: $setup.onSubmit,
    "label-position": "top",
    "initial-values": $setup.Invitation
  }, {
    default: _withCtx(({ isSubmitting }) => [
      _createElementVNode("div", {
        class: _normalizeClass([
        'space-y-4 2xl:space-y-6',
        isSubmitting && 'isSubmitting-rounded',
      ])
      }, [
        _createVNode($setup["Field"], { name: "InvitationCode" }, {
          default: _withCtx(({ value, field, errorMessage }) => [
            _createVNode(_component_el_form_item, {
              class: "inputfield",
              label: "Invitation Code",
              error: errorMessage
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, _mergeProps({ placeholder: "Invitation code" }, field, { "model-value": value }), null, 16, ["model-value"])
              ]),
              _: 2
            }, 1032, ["error"])
          ]),
          _: 1
        }),
        _createElementVNode("button", {
          type: "submit",
          class: "w-full btn btn--green-primary",
          disabled: isSubmitting
        }, " Redeem ", 8, _hoisted_1),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_el_checkbox, {
            modelValue: $setup.isHaveAccount,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.isHaveAccount) = $event)),
            label: "I have an existing RESuite Account",
            size: "large"
          }, null, 8, ["modelValue"])
        ])
      ], 2)
    ]),
    _: 1
  }, 8, ["validation-schema"]))
}